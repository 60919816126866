<template>
  <v-card min-height="779" :class="$vuetify.breakpoint.mdAndUp ? 'card-radius' : ''" color="#C3FFF5" flat>
    <v-row class="justify-md-end justify-space-between mx-6 pt-4">
      <v-icon v-if="$vuetify.breakpoint.smAndDown" class="primary--text" large
        @click="$router.go(-1)">mdi-arrow-left-drop-circle-outline</v-icon>
      <lang-swicther />
    </v-row>
    <v-card-title class="px-12">
      <v-img :src="require('../../assets/logo.png')" max-width="120" contain class="mx-auto mb-8" />
    </v-card-title>
    <v-card-text class="px-4 px-md-12">
      <v-row class="px-4 px-md-12 flex-column">
        <h2 class="text-h6 black--text">
          {{ $t('payment.payment') }}
        </h2>
        <small>{{
          $i18n.locale === 'en' ?
            mealChoice.variantNameEn : mealChoice.variantNameAr
        }}</small>
        <small>{{ mealChoice.date }}</small>
      </v-row>
      <v-row :class="$vuetify.breakpoint.mdAndUp ? 'px-8 mt-4' : 'mt-4'">
        <v-col cols="12" lg="6" md="12">
          <v-card flat :max-width="$vuetify.breakpoint.lgAndUp ? '353' : '100%'" min-height="130"
            class="white custom-radius">
            <v-card-text>
              <h5 class="black--text subtitle-1">{{ $t('payment.paymentDetails') }}</h5>
              <v-row>
                <v-col cols="9" class="">
                  <small>{{
                    $i18n.locale === 'en' ?
                      chosenPlan.titleEn : chosenPlan.titleAr
                  }}</small>
                </v-col>
                <v-col cols="3">
                  <small>{{ mealChoice.price }} KD</small>
                </v-col>
              </v-row>
              <v-row v-if="dietitianInput">
                <v-col cols="9" class="pt-0">
                  <small>Dietitian Consultation</small>
                </v-col>
                <v-col cols="3" class="pt-0">
                  <small>{{ dietitianInput.fee }} KD</small>
                </v-col>
              </v-row>
              <v-divider class="secondary"></v-divider>
              <v-row class="">
                <v-col cols="9" class="black--text">{{ $t('payment.total') }}</v-col>
                <v-col cols="3" class="black--text" v-if="dietitianInput">{{
                  mealChoice.price +
                    dietitianInput.fee
                }} KD</v-col>
                <v-col cols="3" class="black--text" v-else>{{ mealChoice.price }} KD</v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="6" md="12">
          <v-row>
            <v-col cols="12">
              <v-text-field :label="$t('payment.promoCode')" background-color="white darken-3 mt-n4" solo
                v-model="promoCode" dense flat type="text">
                <template slot="append">
                  <v-btn elevation="2" class="mr-n3" @click="applyPromoCode" color="primary">
                    {{ $t('payment.apply') }}
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
            <v-col>
              <v-select :label="$t('payment.deliveryMethod')" dense v-model="paymentMethod" :items="paymentOption" flat
                solo></v-select>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row :class="$vuetify.breakpoint.mdAndUp ? 'px-8' : ''">
        <v-col cols="12" md="12" lg="6">
          <p class="subtitle-2 black--text">{{ $t('payment.deliveryTo') }}</p>
          <v-card flat :max-width="$vuetify.breakpoint.lgAndUp ? '353' : '100%'" min-height="103" color="#FAFAFA"
            class="custom-radius">
            <v-card-text>
              {{ deliveryAdd.addressType }} <br>
              {{
                deliveryAdd.area + ' ' + deliveryAdd.block + ' ' +
                  deliveryAdd.street + ' '
              }}
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="12" lg="6">
          <v-card flat :max-width="$vuetify.breakpoint.lgAndUp ? '353' : '100%'" min-height="150" color="white"
            class="custom-radius">
            <v-card-text>
              <p class="subtitle-2">{{ $t('payment.paymentDetail') }}</p>
              <v-radio-group v-model="paymentType" dense>
                <v-radio v-for="(n, i) in paymentDetail" :key="i" :label="n.text" :value="n.value"></v-radio>
              </v-radio-group>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-layout justify-center>
        <v-btn depressed rounded color="primary custom-font" class="black--text font-weight-light mt-12 mb-12"
          :class="$vuetify.breakpoint.mdAndUp ? 'mr-12' : ''" :width="$vuetify.breakpoint.smAndDown ? 220 : 315"
          :loading="btnLoading" @click="customerCheckout">{{ $t('signUp.btnTitle') }}
          <v-icon right>
            mdi-arrow-right
          </v-icon>
        </v-btn>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import { applyPromoCode } from "../methods/applyPromoCode"
import { customerCheckout } from "../methods/customerCheckout"
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      btnLoading: false,
      promoCode: '',
      paymentType: 'KNET',
      paymentOption: ['ONLINE', 'OFFLINE', 'FREE'],
      paymentMethod: 'ONLINE',
      referralCode: '',
      paymentDetail: [
        { text: 'Knet', value: 'KNET' },
        { text: 'Visa/Master', value: 'VISA/MASTER' },
        { text: 'PayPal', value: 'PAYPAL' }
      ],
      customer: JSON.parse(localStorage.getItem('Customer')) || '',
      deliveryAdd: JSON.parse(localStorage.getItem('deliveryAddress')) || ''
    }
  },
  components: {
    "lang-swicther": () => import("@/core/components/LangSwitch")
  },
  computed: {
    ...mapGetters(['mealChoice', 'chosenPlan', 'dietitianInput']),
    maxWidth() {
      let width = 800
      if (this.$vuetify.breakpoint.md) width = 630
      if (this.$vuetify.breakpoint.lgAndUp) width = 800
      if (this.$vuetify.breakpoint.sm) width = 500
      if (this.$vuetify.breakpoint.xs) width = 400
      return width
    }
  },
  methods: {
    applyPromoCode() {
      applyPromoCode({
        promoCode: this.promoCode,
        packageId: this.chosenPlan._id,
        selected_choice: this.mealChoice.choiceId,
        mealChoice: this.mealChoice._id,
        isReferralApplied: false
      }).then(response => {
        this.mealChoice.price = response.data.applyPromoCode.net_price
      })
    },
    customerCheckout() {
      this.btnLoading = true
      const input = {
        package: this.chosenPlan._id,
        startDate: this.mealChoice.date ||
          (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        promoCode: this.promoCode,
        netPrice: this.mealChoice.price,
        choiceId: this.mealChoice.choiceId,
        // paymentMethod: this.paymentMethod,
        mealChoiceId: this.mealChoice._id,
        referralCode: this.referralCode,
        isReferralApplied: false,
      }
      if (this.dietitianInput) {
        input.dietitionAppointmentInput = this.dietitianInput.dietitionAppointmentInput
        input.netPrice += this.dietitianInput.fee
      }
      customerCheckout({ ...input, paymentType: this.paymentType }).then(response => {
        localStorage.setItem("retryPaymentDetails", JSON.stringify({ ...input, paymentType: this.paymentType }));

        if (response.data.checkoutCustomerPackage.paymentUrl) {
          window.open(response.data.checkoutCustomerPackage.paymentUrl, '_self')
        }
      })
    }
  }
}
</script>

<style>

</style>